<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="primary" depressed>
        {{ textSelected }}
        <v-icon :size="16" right>mdi-chevron-down</v-icon></v-btn
      >
    </template>
    <v-list class="stores-list no-scrollbar" dense>
      <v-list-item @click="onSelectAll" link>
        <v-list-item-action class="mr-4">
          <v-checkbox :input-value="isCheckAll" dense hide-details=""></v-checkbox>
        </v-list-item-action>
        <v-list-item-title>
          Select all stores
        </v-list-item-title>
      </v-list-item>
      <v-list-item-group v-model="selectedStores" multiple dense @change="onSelectStore">
        <v-list-item v-for="(store, index) in stores" :key="index" :value="store.id">
          <template v-slot:default="{ active }">
            <v-list-item-action class="mr-4">
              <v-checkbox :input-value="active" dense></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ store.storeDetail.defaultSystemDomain }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
import STORAGE_NAME from '@/const/storage';

export default {
  props: {
    storeSelectWatcher: Function,
  },
  data() {
    // let { stores } = this.loadQueryFromCache();
    let query = this.$route.query;
    let stores = query?.stores && query?.stores?.split(',');
    let currentStoreId = localStorage.getItem('store-id');
    let textSelected =
      this.selectedStores &&
      this.selectedStores.length > 0 &&
      this.selectedStores.length == 1 &&
      this.selectedStores[0] != currentStoreId
        ? `${this.selectedStores.length} stores selected`
        : 'Current store';
    return {
      selectedStores: stores && stores.length ? stores : [currentStoreId],
      isCheckAll: false,
      timeoutApply: null,
      txtSelectedStores: textSelected,
    };
  },
  computed: {
    ...mapGetters(['stores']),
    textSelected() {
      const currentStoreId = localStorage.getItem('store-id');
      if (this.selectedStores.length == 1 && this.selectedStores.includes(currentStoreId)) {
        return `Current store`;
      } else if (this.selectedStores.length === this.stores.length) {
        return 'All stores';
      } else {
        return `${this.selectedStores.length} stores selected`;
      }
    },
  },
  methods: {
    async onSelectAll() {
      this.selectedStores = [];
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) {
        this.selectedStores = this.stores.map(item => item.id);
      } else {
        const currentStoreId = localStorage.getItem('store-id');
        this.selectedStores = [currentStoreId];
      }
      if (this.timeoutApply) clearTimeout(this.applyTimeout);
      this.timeoutApply = setTimeout(() => {
        this.$props.storeSelectWatcher(this.selectedStores);
        clearTimeout(this.timeoutApply);
      }, 1000);
    },
    onSelectStore() {
      const currentStoreId = localStorage.getItem('store-id');
      if (!this.selectedStores.length) this.selectedStores = [currentStoreId];
      if (this.timeoutApply) clearTimeout(this.applyTimeout);
      this.timeoutApply = setTimeout(() => {
        this.$props.storeSelectWatcher(this.selectedStores);
        clearTimeout(this.timeoutApply);
      }, 2000);
    },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
  },
};
</script>
<style lang="scss" scoped>
.stores-list {
  max-height: 500px;
  z-index: 100;
  overflow-y: scroll;
}
.select-all {
  width: 100%;
}
</style>
