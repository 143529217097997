<template>
  <div class="pick-date-popover">
    <v-menu :close-on-click="true" :close-on-content-click="false" :bottom="true" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" text outlined>
          {{ txtTime }}
          <v-icon right>{{ calendarIcon }}</v-icon></v-btn
        >
      </template>
      <v-card class="pa-3" outlined>
        <label class="label-form mb-1"> Date Range </label>
        <DateRangePicker :handler="handleDateRangeUpdate" :timezone="timezone" />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker';
import moment from 'moment';
import STORAGE_NAME from '@/const/storage';

export default {
  name: 'latte-datetime-btn',
  props: {
    handler: Function,
    timezone: String,
  },
  components: {
    DateRangePicker,
  },
  data: function() {
    // let { startDate, endDate } = this.loadQueryFromCache();
    let query = this.$route.query;
    let { txtTime, periodCompare, yearCompare } = this.calculateDateRange(query?.from_time, query?.to_time);
    return {
      calendarIcon: 'mdi mdi-calendar-range mdi-18px',
      isCompareDate: false,
      comperator: periodCompare,
      periodCompare: periodCompare,
      yearCompare: yearCompare,
      items: [periodCompare, yearCompare],
      txtTime: txtTime,
    };
  },
  computed: {
    defaultPeriodComparator: function() {
      return this.items[0];
    },
  },
  methods: {
    isUseComparator() {},
    handleDateRangeUpdate(dateRange) {
      // dateRange: đã ăn theo timezone rồi, nên không cần insert thêm timezone nữa
      // Khi đẩy ra các chart thì các range đã ăn theo timezone rồi
      // Không đặt được default timezone vì khi đặt default timezone call back ra đến ngoài
      // này bị trừ đi hoặc cộng lên theo timezone mới
      // calculate new compare conditions
      let { txtTime, periodCompare, yearCompare } = this.calculateDateRange(dateRange.startDate, dateRange.endDate);
      this.txtTime = txtTime;
      this.items = [periodCompare, yearCompare];
      this.comperator = periodCompare;

      this.$props.handler(dateRange);
    },
    calculateDateRange(startDate, endDate) {
      /*eslint no-useless-catch: "error"*/
      try {
        // console.log(`timezone: ${this.timezone}`);
        let defaultYearCompare = moment()
          .tz(this.timezone)
          .subtract(1, 'years');

        let txtTime = 'Today';
        let periodStartDate = null;
        let periodEndDate = null;
        let previousYearStartDate = null;
        let previousYearEndDate = null;
        let periodCompare = `Previous period (Yesterday)`;
        let yearCompare = `Previous year (${moment(defaultYearCompare).format('MMM Do YY')})`;
        let currentDate = moment().tz(this.timezone);
        currentDate.set('hour', 0);
        currentDate.set('minute', 0);
        currentDate.set('second', 0);
        let days = moment(currentDate).diff(endDate, 'day');
        if (days > 0) {
          txtTime =
            days > 1
              ? moment(startDate)
                  .format('MMM DD')
                  .toString() +
                ' - ' +
                moment(endDate)
                  .format('MMM DD')
                  .toString()
              : 'Yesterday';
          periodStartDate = moment(startDate).subtract(days, 'days');
          periodEndDate = moment(endDate).subtract(days, 'days');
          previousYearStartDate = moment(startDate).subtract(1, 'years');
          previousYearEndDate = moment(endDate).subtract(1, 'years');
          periodCompare = `Previous period (${moment(periodStartDate).format('MMM DD')} - ${moment(
            periodEndDate,
          ).format('MMM DD')})`;
          yearCompare = `Previous year (${moment(previousYearStartDate).format('MMM DD, YYYY')} - ${moment(
            previousYearEndDate,
          ).format('MMM DD, YYYY')})`;
        } else {
          periodStartDate = moment(startDate).subtract(1, 'days');
          previousYearStartDate = moment(startDate).subtract(1, 'years');
          periodCompare = `Previous period (${moment(periodStartDate).format('MMM DD')})`;
          let currentDay = moment()
            .tz(this.timezone || 'America/Los_Angeles')
            .format('YYYY-MM-DD');
          let daysFromNow = moment(currentDay).diff(startDate);
          txtTime = daysFromNow < 1 ? 'Today' : daysFromNow == 1 ? 'Yesterday' : moment(startDate).format('MMM DD');
          yearCompare = `Previous year (${moment(previousYearStartDate).format('MMM DD, YYYY')})`;
        }
        return { txtTime, periodCompare, yearCompare };
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
  },
  watch: {},
};
</script>

<style scoped>
.text-sm {
  font-size: 14px;
}
</style>
